<template>
  <Head>
    <title></title>
    <meta
      v-if="courseCategory && courseCategory.meta_description !== null"
      name="description"
      :content="courseCategory.meta_description"
    />
    <meta
      v-else
      name="description"
      content="VB Live is the Best Online Coaching Platform for NEET, IIT-JEE, CBSE, Junior IAS Initiative and CA Foundation. We provides all Courses from Class 6 to Class 12 with Experienced Faculty."
    />
    <meta
      v-if="courseCategory && courseCategory.meta_keywords !== null"
      name="keywords"
      :content="courseCategory.meta_keywords"
    />
    <meta
      v-else
      name="keywords"
      content="online training, courses online, online training courses, online tuition, online tuition classes for class 10, best online tuition for class 10, Best online tuition, best courses online, best online training, online tuition classes for class 12, best online tuition for class 12, best online coaching, ias online classes"
    />
  </Head>
  <div class="listing">
    <div class="main-list-wrapper">
      <div class="category-banner">
        <div class="container-fluid">
          <div
            id="carouselExampleSlidesOnly"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div
                v-for="(banner, bannerIndex) in banners"
                :key="bannerIndex"
                class="carousel-item"
                :class="bannerIndex === 0 ? 'active' : ''"
              >
                <a class="img-wrap" href="#">
                  <img :src="banner.image" class="d-block" alt="..." />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="category-feature container">
        <div class="row align-items-center">
          <div class="col-md-5">
            <img src="../assets/category-feat.png" class="d-block" alt="..." />
          </div>
          <div class="col-md-7">
            <div
              v-if="courseCategory"
              class="paragraph"
              v-html="courseCategory.description"
            ></div>
          </div>
        </div>
      </div>
      <div class="category-listingwrap">
        <div class="container">
          <div class="head mb-3">
            <div class="row justify-content-between">
              <div class="col-md-6">
                <!-- <h3>Lorem ipsum dolor sit amet</h3> -->
                <h3>Our courses</h3>
              </div>
              <div class="col-md-2">
                <ul class="list-unstyled filter-list">
                  <li>
                    <select
                      class="form-control select-button std"
                      v-model="standardId"
                      @change="resetProgramsFetch"
                    >
                      <option value="">Select Standard</option>
                      <option
                        v-for="(standard, standardIndex) in standards"
                        :key="standardIndex"
                        :value="standard.id"
                      >
                        {{ standard.standard_title }}
                      </option>
                    </select>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div>
            <!-- <h3>Our courses</h3> -->
            <div v-if="programLoader" class="loading">Loading...</div>
            <div class="row course-row" v-else-if="programs">
              <CourseCard
                v-for="program in programs"
                :key="program.id"
                :program="program"
                :toggleImage="false"
                :toggleBanner="true"
                :togglePrice="true"
                :colValue="colval"
                :toggleFilteredWeeks="true"
                routeLink="CourseDetails"
              />
              <div class="paging-wrapper py-3">
                <v-pagination
                  v-model="coursePage"
                  :pages="totalCoursePages"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="coursePaginateUpdateHandler"
                  v-if="totalCourses > 0"
                />
              </div>
              <EmptyList
                v-if="totalCourses == 0"
                messageText="No courses found!"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="category-videowrap container">
        <div
          v-if="
            courseCategory &&
            courseCategory.youtube_link_one !== null &&
            courseCategory.youtube_link_one_title !== null &&
            courseCategory.youtube_link_one_description !== null
          "
          class="video-card left row g-0 align-items-center"
        >
          <div class="col-md-7">
            <div class="video-wrap">
              <iframe
                width="100%"
                height="315"
                :src="courseCategory.youtube_link_one"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div class="col-md-5">
            <div class="_text">
              <h5>
                {{ courseCategory.youtube_link_one_title }}
              </h5>
              <p>
                {{ courseCategory.youtube_link_one_description }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="
            courseCategory &&
            courseCategory.youtube_link_two !== null &&
            courseCategory.youtube_link_two_title !== null &&
            courseCategory.youtube_link_two_description !== null
          "
          class="video-card right row g-0 align-items-center"
        >
          <div class="col-md-5">
            <div class="_text">
              <h5>
                {{ courseCategory.youtube_link_two_title }}
              </h5>
              <p>
                {{ courseCategory.youtube_link_two_description }}
              </p>
            </div>
          </div>
          <div class="col-md-7">
            <div class="video-wrap">
              <iframe
                width="100%"
                height="315"
                :src="courseCategory.youtube_link_two"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="review-wrapper video-reviews">
      <div class="experience-wrapper container">
        <div
          id="carouselTestimonialsControls"
          class="carousel slide carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval="false"
        >
          <div class="carousel-inner">
            <div
              v-for="(testimonials, testimonialIndex) in sortedTestimonials"
              :key="testimonialIndex"
              class="carousel-item"
              :class="testimonialIndex === 0 ? 'active' : ''"
            >
              <div class="row p-4">
                <div
                  class="col-md-6"
                  v-for="testimonial in testimonials"
                  :key="testimonial.id"
                >
                  <div class="experience-card video-box">
                    <div class="_head">
                      <div class="thumbanil">
                        <img
                          v-if="testimonial.image != null"
                          :src="testimonial.image"
                          alt=""
                        />
                        <img v-else src="../assets/thumb.png" alt="" />
                      </div>
                      <div>
                        <h6>{{ testimonial.name }}</h6>
                        <span>{{ testimonial.designation }}</span>
                      </div>
                    </div>
                    <div v-if="testimonial.type == 0" class="video-wrap">
                      <iframe
                        width="100%"
                        height="215"
                        :src="testimonial.youtube_link"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <p v-else>{{ testimonial.content }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselTestimonialsControls"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            id="nextTestimonialButton"
            data-bs-target="#carouselTestimonialsControls"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
    <CareerCounselling />
  </div>
</template>

<script>
import EmptyList from "@/components/EmptyList.vue";
import CourseCategoryService from "@/services/CourseCategoryService";
import CourseService from "@/services/CourseService";
import CommonService from "@/services/CommonService";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import CareerCounselling from "@/components/CareerCounselling.vue";
import CourseCard from "@/components/CourseCard.vue";
import { Head } from "@vueuse/head";

export default {
  name: "CategoryListing",
  components: {
    Head,
    VPagination,
    EmptyList,
    CareerCounselling,
    CourseCard,
  },
  data() {
    return {
      colval: "col-md-3",
      // Initial page number for program if any
      programLoader: true,
      coursePage: 1,
      coursesPerPage: 12,
      currentCoursePage: 1,
      totalCoursePages: 0,
      totalCourses: 0,
      params: {
        slug: this.$route.params.slug,
      },
      standards: [],
      standardId: "",
      standardText: "",
      courseCategory: null,
      banners: [],
      programs: [],
      sortedTestimonials: [],
    };
  },

  created() {
    if (
      this.$route.query.standard !== undefined &&
      this.$route.query.standard.length !== 0
    ) {
      let standardToFilter = this.$route.query.standard;
      this.standardText = standardToFilter.replace("+", " ");
    } else {
      this.loadPrograms();
    }
    this.loadCategoryDetails();
    this.loadStandards();
  },

  methods: {
    resetProgramsFetch() {
      this.coursePage = 1;
      this.currentCoursePage = 1;
      this.loadPrograms();
    },

    async loadCategoryDetails() {
      await CourseCategoryService.getCourseCategoryDetails(this.params)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.courseCategory = response.data.course_category;
            this.banners = response.data.banners;
            let testimonials = response.data.testimonials;
            if (testimonials != null) {
              let count = testimonials.length;
              let j = 0;
              let k = 0;
              for (let i = 1; i <= count; i++) {
                j = k;
                k = k + 2;
                if (j >= count) {
                  break;
                } else {
                  this.sortedTestimonials.push(testimonials.slice(j, k));
                }
              }
            }
          } else if (response.data.status === "VALIDATION_FAILED") {
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async loadPrograms() {
      let params = {
        page: this.currentCoursePage,
        per_page: this.coursesPerPage,
        category_slug: this.params,
        standard_id: this.standardId,
      };
      await CourseService.getPrograms(params)
        .then((response) => {
          this.programLoader = false;
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.programs = response.data.programs.data;
            // works only when totalCoursePages should be the last_page value
            this.totalCoursePages = response.data.programs.last_page;
            this.currentCoursePage = response.data.programs.current_page;
            this.totalCourses = response.data.programs.total;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async loadStandards() {
      await CommonService.getActiveStandards()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.standards = response.data.standards;
            if (this.standardText !== "") {
              const standardFilter = this.standards.filter((standard) => {
                return standard.standard_title === this.standardText;
              });
              // console.log(standardFilter);
              if (standardFilter.length > 0) {
                this.standardId = standardFilter[0].id;
                this.loadPrograms();
              } else {
                this.programLoader = false;
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    coursePaginateUpdateHandler(selectedPage) {
      if (this.currentCoursePage !== selectedPage) {
        this.currentCoursePage = selectedPage;
        this.scrollToElement(this.$refs.courses_head);
        this.loadPrograms();
      }
    },

    scrollToElement(refId) {
      const el = refId;
      if (el) {
        el.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/style/listing.scss";
</style>
