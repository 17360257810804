import Http from "@/services/Http";

export default {
  getCourseCategories() {
    return Http().post("course/category/all");
  },
  getCourseCategoryDetails(data) {
    return Http().post("course/category/details", data);
  },
};
